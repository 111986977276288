<template>
  <div>
    <headerTop></headerTop>
    <headerMin @search="searchInit"></headerMin>
    <!-- 主体 -->
    <div class="allGoods">
      <!-- 索引 -->
      <div class="indexes wrap">
        <span v-if="cateObj1.category_name">{{ cateObj1.category_name }}</span>
        <span v-else>全部商品</span>
        <span v-if="cateObj2.category_name">> {{ cateObj2.category_name }}</span>
      </div>
      <div class="content wrap bgw">
        <!-- 索引列表 -->
        <div class="indexesList fl">
          <div
            :class="{active1:activeType1 == i}"
            @click="NavTab1(v.id,i,v)"
            v-for="(v,i) in classTitle"
            :key="i"
            class="title"
          >
            {{v.category_name}}
            <img
              class="fr"
              src="@/assets/image/Listofcommodities_Open_icon@2x.png"
            />
            <div
              v-show="activeType1 == i"
              @click.stop
              @click="NavTab2(a.id,b,v)"
              v-for="(a,b) in v.childData"
              :key="b"
              class="list"
            >
              <p class="one">
                <img
                  v-if="activeType2 !=b"
                  src="@/assets/image/Listofcommodities_plus_icon@2x.png"
                  alt
                />
                <img
                  v-if="activeType2 ==b"
                  src="@/assets/image/Listofcommodities_reduce_icon@2x.png"
                  alt
                />
                {{a.category_name}}
              </p>
              <div class="txt">
                <p
                  v-show="activeType2 == b"
                  @click.stop
                  @click="NavTab3(c.id)"
                  v-for="(c,d) in a.childData"
                  :key="d"
                  class="one"
                >{{c.category_name}}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 商品列表 -->
        <div class="goodsList fr">
          <div class="pageTop">
            <span>{{currentPage}}</span>
            / {{lastPage}}
            <!-- <img src="@/assets/image/Listofcommodities_Left_icon@2x.png" alt />
            <img src="@/assets/image/Listofcommodities_right_icon@2x.png" alt />-->
          </div>
          <!-- 商品详情 -->
          <div v-if="List1.state == 0 && activeType1 == -1" class="noneSearch">
            抱歉，没有找到与 "
            <span>{{List1.title}}</span> " 相关的内容
          </div>

          <div
            @click="GoGoodsDetails(v)"
            :key="i"
            v-for="(v,i) in goodsList"
            :class="{all_goodsDetails: 'all_goodsDetails', nth4: (i + 1) % 4 == 0}"
          >
            <div class="img_BOx">
              <img :src="v.goods_img" alt />
            </div>
            <p class="one">￥{{v.price}}</p>
            <p class="two">{{v.goods_name}}</p>
            <p class="one">
              评论
              <span>{{v.commentSum}}</span>
              条
            </p>
            <button @click="GoGoodsOrder()">立即购买</button>
          </div>
          <div class="page" v-if="showPage">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="16"
              @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import headerMin from "@/components/index/index_header_min";
import publicBottom from "@/components/public/public_bottom";

import { getAllCate } from "@/request/index"; //所有分类
import { Goods_indexs } from "@/request/index"; // 获取 分类或搜索的商品列表
import { getHistoryGoodList } from "@/request/index";
export default {
  components: {
    headerTop,
    headerMin,
    publicBottom
  },
  data() {
    return {
      searchState: 0,
      classTitle: [], //索引 列表
      goodsList: [], // 商品列表
      List1: [], //搜索 列表
      activeType1: 0,
      activeType2: 0,
      id: 0,
      total: 0,
      cateObj1: {}, // 当前选中的科室对象
      cateObj2: {}, // 当前选中的2级科室
      currentPage: 1,
      lastPage: 1,
      showPage: false, // 是否显示分页
      keyword: "" // 搜索关键字
    };
  },
  mounted() {
    this.start();
  },
  methods: {
    searchInit(e) {
      this.keyword = e;
      this.allGoods();
      // this.List1 = e;
      // if (e.state == 1) {
      //   if (e.data.goods_list != "") {
      //     this.goodsList = e.data.goods_list;
      //   }
      // }
    },
    Goindex() {
      this.$router.replace({ path: "/" });
    },
    NavTab1(id, i, v) {
      this.id = id;
      this.cateObj1 = v;
      this.cateObj2 = {};
      this.keyword = "";
      if (this.activeType1 == i) {
        this.activeType1 = -1;
      } else {
        this.activeType1 = i;
      }
      this.allGoods();
    },
    NavTab2(id, i, v) {
      this.id = id;
      this.cateObj2 = v.childData[id];
      this.keyword = "";
      if (this.activeType2 == i) {
        this.activeType2 = -1;
      } else {
        this.activeType2 = i;
      }
      this.allGoods();
    },
    addAnimation() {},
    NavTab3() {},
    GoGoodsOrder() {
      this.$router.push({ path: "/goodsOrder?id=" + this.goods.id });
    },
    start() {
      let msg = this.$route.query.search;
      if (msg == undefined) {
        // this.allGoods();
        this.getHistoryList();
      } else {
        this.activeType1 = -1;
      }
      getAllCate().then(res => {
        if (res.code == 1) {
          this.classTitle = res.data;
          this.id = Object.keys(res.data)[0];
        }
      });
    },
    getHistoryList() {
      let shopId = localStorage.getItem("lzyyShopId");
      getHistoryGoodList({
        token: this.$token,
        shop_id: shopId
      }).then(res => {
        if (res.code == 1) {
          this.goodsList = res.data.data;
          this.goodsList  = this.goodsList.filter(item => item != null)
          this.lastPage = res.data.last_page;
          this.total = res.data.total;
          this.showPage = res.data.last_page != 1;
        }
      });
    },
    GoGoodsDetails(e) {
      this.$router.push({ path: "/goodsDetails?id=" + e.id });
    },
    allGoods(page = 1) {
      let shopId = localStorage.getItem("lzyyShopId");
      Goods_indexs({
        shop_id: shopId,
        category_id: this.id,
        keyword: this.keyword,
        pagenum: 16,
        page
      }).then(res => {
        if (res.code == 1) {
          this.goodsList = res.data.data;
          this.lastPage = res.data.last_page;
          this.total = res.data.total;
          this.showPage = res.data.last_page != 1;
        } else if (res.code == 0) {
          this.$message.error("获取失败，没有此类型的药品");
        }
      });
    },
    changePage(page) {
      this.currentPage = page;
      this.allGoods(page);
    }
  }
};
</script>
<style lang="less" scoped>
//  全部商品页面
.allGoods {
  // 索引
  .indexes {
    height: 44px;
    line-height: 44px;
    font-size: 14px;
  }
  //   没有搜索内容
  .noneSearch {
    padding-top: 40px;
    width: 1000px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    text-align: center;
    span {
      color: red;
    }
  }
  .content {
    overflow: hidden;
  }

  // 索引列表
  .indexesList {
    width: 192px;

    .title {
      background: #dddddd;
      line-height: 40px;
      position: relative;
      min-height: 40px;
      text-indent: 12px;

      > img {
        width: 6px;
        height: 10px;
        vertical-align: middle;
        margin-top: 14px;
        margin-right: 12px;
      }
    }

    .list {
      min-height: 32px;
      line-height: 32px;
      text-indent: 12px;
      background: rgba(245, 245, 245, 1);
      border: 1px solid rgba(221, 221, 221, 1);

      img {
        width: 12px;
        height: 12px;
        // vertical-align: middle;
        margin-right: 4px;
      }
    }

    .txt {
      text-indent: 12px;
      background: white;
      border: 1px solid rgba(221, 221, 221, 1);

      p {
        line-height: 30px;
      }
    }
  }

  // 页码
  .pageTop {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding-right: 15px;
    padding-top: 8px;
    position: relative;
    text-align: right;
    background: rgba(245, 245, 245, 1);
    border: 1px solid rgba(230, 230, 230, 1);
    margin-bottom: 10px;
    span {
      color: red;
    }
  }

  // 商品 列表
  .goodsList {
    padding-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    width: 1000px;
  }

  // 商品详情
  .all_goodsDetails {
    width: 240px;
    height: 405px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(204, 204, 204, 1);
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    margin-bottom: 40px;
    margin-right: 12px;
    box-sizing: border-box;
    .img_BOx {
      width: 100%;
      height: 248px;
    }

    img {
      width: 100%;
      height: 248px;
    }

    p {
      padding-left: 10px;
    }

    span {
      color: #f00000;
    }

    p:nth-of-type(1) {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(240, 0, 0, 1);
      margin-bottom: 6px;
      margin-top: 4px;
    }

    p:nth-of-type(2) {
      height: 37px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 20px;
      margin-bottom: 10px;
    }

    p:nth-of-type(3) {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 20px;
      margin-bottom: 10px;
    }

    button {
      width: 208px;
      height: 32px;
      background: rgba(240, 0, 0, 1);
      border-radius: 4px;
      margin: auto;
      display: block;
      cursor: pointer;
    }
  }
  .nth4 {
    margin-right: 0px;
  }
}

.active1 {
  > img {
    transform: rotate(90deg);
  }
}
.indexesList > .list {
  transition: height 1s;
}

.page {
  width: 100%;
  text-align: center;
}
</style>